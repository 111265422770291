<template>
  <!-- 财务账单详情 -->
  <div
    class="container"
    :class="{ 'container-spinning': spinning }"
    id="order-status"
  >
    <a-spin tip="Loading..." v-if="spinning"> </a-spin>

    <a-page-header
      :title="title"
      :sub-title="`订单号：${info.outTradeNo}`"
      @back="() => $router.go(-1)"
    />

    <div class="main-content" v-if="info">
      <div class="content-item">
        <div class="content-item-header">
          <div class="title">订单状态</div>
        </div>
        <a-steps :current="stepsCurrent" :status="stepStatus" progress-dot>
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>
      </div>

      <div class="content-item" id="accounts-info" ref="printContent">
        <div class="content-item-header">
          <div class="title">财务明细</div>
          <div class="item print-btn" @click="onOrderItemInfoClick">
            <a-button> 显示订单详情 </a-button>
          </div>
        </div>

        <div class="content-sub-title">概述</div>

        <div class="ul">
          <div class="li">
            <span>订单总金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.totalFee"
            />
          </div>
          <div class="li">
            <span>实际付款金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.paidFee"
            />
          </div>
          <div class="li">
            <span>优惠金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.discountFee"
            />
          </div>
        </div>
        <div class="ul ul-2">
          <div class="li">
            <span>支付时间：</span>
            <span>{{ info.payAt }}</span>
          </div>
          <div class="li">
            <span>下单时间：</span>
            <span>{{ info.createAt }}</span>
          </div>
          <div class="li">
            <span>支付方式：</span>
            <span>微信支付</span>
          </div>
        </div>
        <div class="ul ul-2">
          <div class="li">
            <span>商户交易单号：</span>
            <a-tooltip
              placement="topLeft"
              :title="info.transaction_id"
              v-if="info.transaction_id"
            >
              {{ info.transaction_id }}
            </a-tooltip>
            <span v-else>-</span>
          </div>
        </div>

        <template v-if="info.orderCancel">
          <a-divider />

          <div class="content-sub-title">退款明细</div>

          <div class="ul">
            <div class="li">
              <span>退款状态：</span>
              <span>{{ refundStatus[info.orderCancel.status] }}</span>
            </div>
            <div class="li">
              <span>资金退回方式：</span>
              <span>微信钱包</span>
            </div>
            <div class="li">
              <span>退款金额：</span>
              <a-statistic
                class="a-statistic"
                prefix="￥"
                :value="info.orderCancel.refundFee"
              />
            </div>
          </div>
          <div class="ul ul-2">
            <div class="li">
              <span>商户交易号：</span>
              <span v-if="info.orderCancel.refundNo">{{
                info.orderCancel.refundNo
              }}</span>
              <span v-else>-</span>
            </div>
            <div class="li">
              <span>退款时间：</span>
              <span v-if="info.orderCancel.finshAt">{{
                info.orderCancel.finshAt
              }}</span>
              <span v-else>-</span>
            </div>
          </div>
        </template>

        <template v-if="info.rate.length">
          <a-divider />

          <div class="content-sub-title">各级业绩概括</div>

          <a-table
            :columns="columns"
            :data-source="info.rate"
            :pagination="false"
            :row-key="(record) => Number(record.outTradeNo)"
            :scroll="{ x: 1300 }"
          >
            <template slot="price" slot-scope="record">
              <a-statistic prefix="￥" :value="record.price" />
            </template>
          </a-table>
        </template>
      </div>
    </div>

    <div class="embedded-page-wrap" v-if="isOrderItemInfo">
      <div class="embedded-page">
        <div class="embedded-page-header">
          <div class="embedded-page-header-text">订单详情</div>
          <div class="embedded-page-close-btn" @click="onOrderItemInfoClose">
            <a-icon type="close" />
          </div>
        </div>
        <order-item-info></order-item-info>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import orderItemInfo from './order-item-info'
import NP from 'number-precision'
import { formatTime } from '../../utils/public'
let type = null
export default {
  data() {
    return {
      spinning: true,
      title: '',
      info: {
        outTradeNo: '',
        rate: []
      },
      stepsCurrent: 0,
      stepStatus: 'process',
      steps: [],
      columns: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          customRender: (text, record, index) => {
            if (index < this.info.rate.length - 1) {
              return index + 1
            }
            return {
              children: <span style="font-weight: bold;">{text}</span>,
              attrs: {
                colSpan: 7
              }
            }
          }
        },
        {
          title: '业绩类型',
          width: 150,
          dataIndex: 'userType',
          customRender: (text, record, index) => {
            if (index < this.info.rate.length - 1) {
              return this.typeText[text]
            }
            return {
              children: '',
              attrs: {
                colSpan: 0
              }
            }
          }
        },
        {
          title: '账号名',
          dataIndex: 'systemNum',
          width: 200,
          customRender: this.renderContent
        },
        {
          title: '账号使用人',
          dataIndex: 'name',
          width: 150,
          customRender: this.renderContent
        },
        {
          title: '业绩流水号',
          dataIndex: 'outTradeNo',
          width: 200,
          customRender: this.renderContent
        },
        {
          title: '提现状态',
          width: 150,
          dataIndex: 'status',
          customRender: (text, record, index) => {
            if (index < this.info.rate.length - 1) {
              return this.withdrawStatusText[text]
            }
            return {
              children: '',
              attrs: {
                colSpan: 0
              }
            }
          }
        },
        {
          title: '提现_商户交易号',
          dataIndex: 'payment_no',
          customRender: (text, record, index) => {
            if (index < this.info.rate.length - 1) {
              return text ? text : '-'
            }
            return {
              children: '',
              attrs: {
                colSpan: 0
              }
            }
          }
        },
        {
          title: '业绩金额',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'price' }
        }
      ],
      typeText: {
        servicer: '作业师傅',
        lector: '理论讲师',
        training: '实操讲师',
        visitiservicer: '上门实训师傅',
        proxy: '代理企业',
        apprentice: '学徒',
        memberSpread: '用户分享码',
        servicerSpread: '师傅分享码',
        business: '渠道商',
        bterminal: '渠道商终端',
        proxyDividend: '企业分红',
        community:"渠道经理费用",
        madminDividend:"平台分红",
        Madmin:"平台",
        recomm:"推荐使用者",
        fowller:"监工",
      },
      withdrawStatusText: {
        0: '待提现',
        1: '会计审核',
        2: '出纳审核',
        3: '会计审核拒绝',
        4: '出纳审核拒绝',
        5: '出纳打款中',
        6: '提现成功(微信支付)',
        7: '提现成功(人工)'
      },
      refundStatus: {
        0: '退款中',
        1: '退款成功',
        2: '退款失败'
      },
      anchors: [
        {
          href: '#order-status',
          title: '订单状态'
        },
        {
          href: '#accounts-info',
          title: '财务明细'
        },
        {
          href: '#order-info',
          title: '订单详情'
        },
        {
          href: '#order-user',
          title: '下单用户'
        }
      ],
      isOrderItemInfo: false
    }
  },
  computed: {
    ...mapState([
      '$orderStatusText',
      '$orderSurroundingsStatuText',
      '$orderGoodsStatuText'
    ])
  },
  components: {
    orderItemInfo
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    type = query.type
    console.log('query', query)
    if (type === 'serving') {
      this.getOrderItemAccountsInfo(query.id)
    } else if (type === 'goods') {
      this.getOrderGoodsAccountsInfo(query.id)
    } else if (type === 'card') {
      this.getAccountreadCard(query.id)
    } else {
      this.getOrderSurroundingsAccountsInfo(query.id)
    }
  },
  methods: {
    renderContent(text, record, index) {
      const obj = {
        children: text,
        attrs: {}
      }
      if (index === this.info.rate.length - 1) {
        obj.attrs.colSpan = 0
      }
      return obj
    },
    getOrderItemAccountsInfo(id) {
      const data = {
        orderId: id
      }
      this.$axios.getOrderItemAccountsInfo(data).then((res) => {
        const d = res.data.data
        this.setSteps(d)
        this.setPrice(d)
        this.info = d
        this.spinning = false
      })
    },
    getAccountreadCard(id) {
      const data = {
        orderId: id
      }
      this.$axios.getAccountreadCard(data).then((res) => {
        const d = res.data.data
        this.setSteps(d)
        this.setPrice(d)
        this.info = d
        this.spinning = false
      })
    },
    getOrderSurroundingsAccountsInfo(id) {
      const data = {
        orderId: id
      }
      this.$axios.getOrderSurroundingsAccountsInfo(data).then((res) => {
        const d = res.data.data
        this.setSteps(d)
        this.setPrice(d)
        this.info = d
        this.spinning = false
      })
    },
    getOrderGoodsAccountsInfo(id) {
      const data = {
        orderId: id
      }
      this.$axios.getOrderGoodsAccountsInfo(data).then((res) => {
        const d = res.data.data
        this.setSteps(d)
        this.setPrice(d)
        this.info = d
        this.spinning = false
      })
    },
    setSteps(d) {
      // 设置步骤条
      const status = d.status // 订单状态 0：待支付 1：待确认 2：待上门 3：开始上门 4：开始服务 5：服务完成 6：验收评价 7: '用户取消订单' 8: '平台取消订单'
      let times = []
      if (type === 'serving') {
        this.steps = [
          {
            title: '提交订单',
            description: ''
          },
          {
            title: '支付订单',
            description: ''
          },
          {
            title: '确认接单',
            description: ''
          },
          {
            title: '开始上门',
            description: ''
          },
          {
            title: '到达/开始服务',
            description: ''
          },
          {
            title: '服务完成',
            description: ''
          },
          {
            title: '服务验收评价',
            description: ''
          }
        ]
        times = [
          'createAt', // 创建时间
          'payAt', // 用户支付时间
          'acceptAt', // 接单时间
          'visitingAt', // 上门时间
          'servingAt', // 服务时间
          'finishAt', // 完成时间
          'checkAt' // 验收评分时间
        ]
        if (status < 7) {
          this.stepsCurrent = status
        } else {
          this.stepsCurrent = d.beforeStatus
          const step = this.steps[d.beforeStatus]
          step.title = this.$orderStatusText[status]
          step.description = d.orderCancel.finshAt
          this.stepStatus = 'error'
        }
      } else {
        this.steps = [
          {
            title: '提交订单',
            description: ''
          },
          {
            title: '支付订单',
            description: ''
          },
          {
            title: '处理中',
            description: ''
          },
          {
            title: '订单完成',
            description: ''
          }
        ]
        times = [
          'createAt', // 创建时间
          'payAt', // 用户支付时间
          'servingAt', // 服务时间
          'finishAt' // 完成时间
        ]
        const statusIndex = {
          4: 2,
          5: 3,
          8: 3
        }
        if (status < 6) {
          this.stepsCurrent = statusIndex[status]
        } else {
          this.stepsCurrent = 3
          const step = this.steps[3]
          step.description = formatTime(d.orderCancel.finshAt)
          this.stepStatus = 'error'
          if (type === 'goods') {
            step.title = this.$orderGoodsStatuText[status]
          } else {
            step.title = this.$orderSurroundingsStatuText[status]
          }
        }
      }
      this.steps.forEach((e, i) => {
        if (d[times[i]]) {
          const t = formatTime(d[times[i]])
          e.description = t
        }
      })
    },
    setPrice(d) {
      // 设置金额
      let price = 0
      d.rate.forEach((e) => {
        //e.price存在值就不会出现问题，没有值需要加判断
        if(e.price){
           price = NP.plus(price, e.price)
        }
       
      })
      const row = {
        id: 0,
        sort: '总计金额',
        userType: '',
        systemNum: '',
        outTradeNo: '',
        status: '',
        payment_no: '',
        price: price
      }
      d.rate.push(row)
    },
    getContainer() {
      // 锚点指定滚动的容器
      return document.getElementById('main-wrap')
    },
    handleAnchorClick(e, link) {
      // 锚点点击事件
      e.preventDefault()
      if (link.href) {
        // 找到锚点
        const href = link.href.slice(1)
        let anchorElement = document.getElementById(href)
        // 如果对应id的锚点存在，就跳转到锚点
        if (anchorElement) {
          anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    },
    onOrderItemInfoClick() {
      console.log('this.$route.query', this.$route.query)
      let type = this.$route.query.type
      // 显示订单详情弹窗
      if (type === 'card') {
        if (this.info.id !== this.info.orderInfo.id) {
          this.$router.replace({
            query: {
              id: this.info.orderInfo.id
            }
          })
        }
      } else {
        if (this.info.id !== this.info.orderInfo.orderId) {
          this.$router.replace({
            query: {
              id: this.info.orderInfo.orderId
            }
          })
        }
      }
      this.isOrderItemInfo = true
    },
    onOrderItemInfoClose() {
      // 关闭订单详情弹窗
      if (this.info.id !== this.info.orderInfo.orderId) {
        this.$router.replace({
          query: {
            id: this.info.id
          }
        })
      }
      this.isOrderItemInfo = false
    }
  }
}
</script>
<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.container {
  position: relative;
}

.container-spinning {
  height: 100vh;
  overflow: hidden;
}

.ant-spin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.ant-page-header {
  position: inherit;
  z-index: 10;
}

.content-item:first-child {
  padding: 0;
}

.content-item:first-child .content-item-header {
  padding: 16px 32px;
  border-bottom: 1px solid #e9e9e9;
}

.content-item:first-child .ant-steps {
  padding: 24px 32px;
}

.anchor {
  position: fixed;
  right: 0;
  bottom: 20%;
  width: 152px;
  z-index: 999;
  padding: 12px 8px;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 21, 41, 0.1);
}
</style>
